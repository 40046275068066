define("ember-data-change-tracker/transforms/object", ["exports", "ember-data", "ember"], function (_exports, _emberData, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Transform.extend({
    serialize: function (value) {
      return value && JSON.stringify(value);
    },
    deserialize: function (value) {
      if (_ember.default.isEmpty(value)) {
        return {};
      }
      if (_ember.default.typeOf(value) === "object") {
        return value;
      }
      if (_ember.default.typeOf(value) === 'string') {
        return JSON.parse(value);
      }
    }
  });
});