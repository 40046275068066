define("ember-intl/-private/formatters/format-relative", ["exports", "@ember/debug", "fast-memoize", "intl-messageformat", "ember-intl/-private/error-types", "ember-intl/-private/formatters/-base"], function (_exports, _debug, _fastMemoize, _intlMessageformat, _errorTypes, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
  // `Intl.RelativeTimeFormat` will be added in TypeScript 4.0
  // @see https://github.com/microsoft/TypeScript/pull/36084#issuecomment-649080072

  /**
   * Unit to use in the relative time internationalized message.
   *
   * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/format#Parameters).
   *
   * [Specification](https://tc39.es/ecma402/#sec-singularrelativetimeunit).
   */

  /**
   * The format of output message.
   *
   * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#Parameters).
   *
   * [Specification](https://tc39.es/ecma402/#sec-InitializeRelativeTimeFormat).
   */

  /**
   * The length of the internationalized message.
   *
   * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#Parameters).
   *
   * [Specification](https://tc39.es/ecma402/#sec-InitializeRelativeTimeFormat).
   */

  /**
   * An object with some or all of properties of `options` parameter
   * of `Intl.RelativeTimeFormat` constructor.
   *
   * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#Parameters).
   *
   * [Specification](https://tc39.es/ecma402/#sec-InitializeRelativeTimeFormat).
   */

  const RELATIVE_TIME_OPTIONS = ['numeric', 'style', 'unit'];

  /**
   * @private
   * @hide
   */
  class FormatRelative extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "createNativeFormatter", (0, _fastMemoize.default)((locales, options) => {
        if (!Intl || !Intl.RelativeTimeFormat) {
          const error = new _intlMessageformat.FormatError("Intl.RelativeTimeFormat is not available in this environment. Try polyfilling it using \"@formatjs/intl-relativetimeformat\"", _errorTypes.MISSING_INTL_API);
          this.config.onError({
            kind: _errorTypes.MISSING_INTL_API,
            error
          });
          throw error;
        }
        return new Intl.RelativeTimeFormat(locales, options);
      }));
    }
    get options() {
      return RELATIVE_TIME_OPTIONS;
    }
    format(locale, value, formatOptions) {
      var _formatOptions$unit;
      const formatterOptions = this.readOptions(formatOptions);
      this.validateFormatterOptions(locale, formatterOptions);
      const unit = (_formatOptions$unit = formatOptions === null || formatOptions === void 0 ? void 0 : formatOptions.unit) !== null && _formatOptions$unit !== void 0 ? _formatOptions$unit : formatterOptions.unit;
      (false && !(unit) && (0, _debug.assert)("[ember-intl] FormatRelative: 'formatOptions' are missing a 'unit'.", unit));
      const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
      return formatterInstance.format(typeof value === 'number' ? value : new Date(value).getTime(), unit);
    }
  }
  _exports.default = FormatRelative;
  _defineProperty(FormatRelative, "type", 'relative');
});