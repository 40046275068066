define("ember-data-change-tracker/mixins/keep-only-changed", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // EmberData does not serialize hasMany relationships by default
  var _default = _exports.default = _ember.default.Mixin.create({
    keepValue(record, key) {
      return record.get('isNew') || record.didChange(key);
    },
    serializeAttribute: function (snapshot, json, key) {
      if (this.keepValue(snapshot.record, key)) {
        return this._super(...arguments);
      }
    },
    serializeBelongsTo: function (snapshot, json, relationship) {
      if (this.keepValue(snapshot.record, relationship.key)) {
        return this._super(...arguments);
      }
    },
    serializeHasMany: function (snapshot, json, relationship) {
      if (this.keepValue(snapshot.record, relationship.key)) {
        return this._super(...arguments);
      }
    }
  });
});