define("ember-intl/macros/intl", ["exports", "@ember/object", "@ember/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__intlInjectionName = void 0;
  _exports.default = intl;
  /**
   * @private
   * @hide
   */
  const __intlInjectionName = _exports.__intlInjectionName = "intl-".concat(Date.now().toString(36));
  function intl() {
    for (var _len = arguments.length, dependentKeysAndGetterFn = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeysAndGetterFn[_key] = arguments[_key];
    }
    const getterFn = dependentKeysAndGetterFn.pop();
    const dependentKeys = dependentKeysAndGetterFn;
    return (0, _object.computed)("".concat(__intlInjectionName, ".locale"), ...dependentKeys, function (propertyKey) {
      if (!(0, _object.get)(this, __intlInjectionName)) {
        (0, _object.defineProperty)(this, __intlInjectionName, {
          value: (0, _application.getOwner)(this).lookup('service:intl'),
          enumerable: false
        });
      }
      const intl = (0, _object.get)(this, __intlInjectionName);
      return getterFn.call(this, intl, propertyKey, this);
    });
  }
});